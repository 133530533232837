<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!myteamid">
          Add My Team
        </h1>
        <h1 v-if="myteamid">
          Edit My Team
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="myteam()"
        >
          My Team
        </el-button>
        <el-button
          type="success"
          @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!myteamid"
          title="Myteam Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="myteamid"
          title="Myteam Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
      <div v-if="showAlertSlugexit">
        <el-alert
          title="The slug has already been taken."
          type="error"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition"
  >
    <el-tabs type="border-card">
      <el-tab-pane label="Team Info">  
        <div class="panel panel-default">
          <el-row :gutter="20">
		  <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
		  <el-form-item
                label="Team Type"
                prop="user_type"
              >
                <el-select
                  v-model="data.user_type"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in usertype_Array"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
				 
                </el-select>
              </el-form-item>
			   </el-col>
		  </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="First Name"
                prop="fname"
              >
                <el-input
                  v-model="data.fname"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Last Name"
                prop="lname"
              >
                <el-input
                  v-model="data.lname"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="!myteamid">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Email"
                prop="email"
              >
                <el-input
                  v-model="data.email"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Password"
                prop="password">
                <el-input
                  v-model="data.password"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Designation"
                prop="designation"
              >
                <el-input
                  v-model="data.designation"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
			
              <el-form-item
                label="Phone"
                prop="phone"
              >
                <el-input
                  v-model="data.phone"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
             
            </el-col>
          </el-row>    
          <el-row :gutter="20">
		  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
		label="Country"
		prop="country"
		>
		<el-select v-model="data.country_name" placeholder="Select Country">
			<el-option
			v-for="item in countries"
			:key="item.value"
			:value="item.value"
			:label="item.label"
			@click="getStates(item.value)"
			></el-option>
		</el-select>
		</el-form-item>
		</el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
			<el-form-item
		label="State"
		prop="state"
		>
		<el-select v-model="data.state_name"  placeholder="Select Region / State">
		<el-option              
		v-for="item in states"
		:key="item.value"
		:label="item.label"
		:value="item.value"
		@click="getCities(item.value)"
		/>
		</el-select>
		</el-form-item>
             
            </el-col>
          </el-row>  
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
             <el-form-item
		label="City"
		prop="city"
		>
		<el-select v-model="data.city_name" placeholder="City">
		<el-option              
		v-for="item in cities"
		:key="item.value"
		:label="item.label"
		:value="item.value"
		/>
		</el-select>
		</el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Address"
                prop="address"
              >
                <el-input
                  v-model="data.address"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row> 
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
			 <el-form-item
                label="Postal/Zip Code"
                prop="postalcode"
              >
                <el-input
                  v-model="data.postalcode"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Agent Board ID/Numbe"
                prop="vnumber"
              >
                <el-input
                  v-model="data.vnumber"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row> 
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Brokerage"
                prop="brokerage"
              >
                <el-input
                  v-model="data.brokerage"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Website"
                prop="website"
              >
                <el-input
                  v-model="data.website"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="12"
            >
              <el-form-item
                label="Description"
                prop="description"
              >
               <TinyMce
              v-if="loadEditor"
              v-bind:content="data.description"
              @contentChanged="updateTextAreaValue"
          >
          </TinyMce>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item 
                label="Photo"
                prop="photo"
              >
                <el-upload
                  class="upload-demo"
                  drag
                  :action="imageUploadUrl"
                  list-type="picture"
                  :headers="imageUploadHeaders"
                  name="file"
                  :file-list="fileList"
                  :limit="1"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :on-success="handleImageUploadSuccess"
                  :on-error="handleImageUploadError"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em><br>
                    JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row> 
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Meta Tag Title"
                prop="meta_title"
              >
               
              <el-input
          v-model="data.meta_title"
          type="textarea"
        />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Meta Tag Description"
                prop="meta_description"
              >
              <el-input
          v-model="data.meta_description"
          type="textarea"
        />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
           
              <el-form-item
                label="Meta Tag Keywords"
                prop="meta_keyword"
              > <el-input
          v-model="data.meta_keyword"
          type="textarea"
        />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Status"
                prop="status"
              >
                <el-select
                  v-model="data.status"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in selectstatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item>
                <input
                  id="myteamid"
                  v-model="myteamid"
                  name="myteamid"
                  type="hidden"
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Lead Routing">
        <div class="panel panel-default">
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Min Price"
                prop="min_price"
              >
                <el-input
                  v-model="data.min_price"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Max Price"
                prop="max_price"
              >
                <el-input
                  v-model="data.max_price"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Min Area"
                prop="min_area"
              >
                <el-input
                  v-model="data.min_area"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Max Area"
                prop="max_area"
              >
                <el-input
                  v-model="data.max_area"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Min Score"
                prop="min_score"
              >
                <el-input
                  v-model="data.min_score"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            > 
              <el-form-item
                label="Max Score"
                prop="max_score"
              >
                <el-input
                  v-model="data.max_score"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Listing Type"
                prop="listing_type"
              >
                <el-select
                  v-model="data.listing_type"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in ListingType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            />
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>
<script>
import MyteamService from "@/api/myteam.service";
import authHeader from '@/api/auth-header';
import CountryService from "@/api/country.service";
import TinyMce from "@/components/TinyMce"
  export default {
       components: {
      TinyMce
    },
    data() {
      var checkFname = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the First Name.'));
        } else {
          callback();
        }
      };
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Description.'));
        } else {
          callback();
        }
      };
      var checkEmail = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Email.'));
        } else {
          callback();
        }
      };
      var checkPassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Password.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        myteamid: this.$route.params.myteam,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
		selectstatus:[{
          value: 'Enabled',
          label: 'Enabled'
        }, {
          value: 'Disabled',
          label: 'Disabled'
        }],




       usertype_Array:[{
          value: 'award_winning_team',
          label: 'Award Winning Team'
        }, {
          value: 'realtor_team',
          label: 'Realtor Team'
        }, {
          value: 'mortgages_team',
          label: 'Mortgages Team'
        }, {
          value: 'management_team',
          label: 'Management Team'
        },{
          value: 'property_rental_management_team',
          label: 'Property Rental Magement Team'
        }],
        fileList: [],
		countries: [],
		states:[],
        cities:[],
        data: {
          lname: null,
          photo: null,
          imageNew: null,
          description: null,
          slug: null,
          meta_title: null,
          meta_description: null,
          meta_keyword: null,
          posted_on: null,
          status: "Enabled",
          user_type: null,
		 country: null,
		country_name: null,
		state_name:null,
		state:null,
		city_name:null,
		city: null,
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          fname: [
            { validator: checkFname, trigger: 'blur' }
          ],
          description: [
            { validator: checkDescription, trigger: 'blur' }
          ],         
          email: [
            { validator: checkEmail, trigger: 'blur' }
          ],
           password: [
            { validator: checkPassword, trigger: 'blur' }
          ]
        }
      };
    },
		watch: {
		data: function () {
			if(!this.data.country_name && this.data.country) {
				this.data.country_name = this.extractNameById(this.data.country, this.countries);
			}
			if(!this.data.state_name && this.data.state) {
				this.data.state_name = this.extractNameById(this.data.state, this.states);
			}
			if(!this.data.city_name && this.data.city) {
				this.data.city_name = this.extractNameById(this.data.city, this.cities);
			}			
		},
		countries: function () {
		this.data.country_name = this.extractNameById(this.data.country, this.countries);
		}
		,states: function () {
		this.data.state_name = this.extractNameById(this.data.state, this.states);
		},
		cities: function () {
		this.data.city_name = this.extractNameById(this.data.city, this.cities);
		},
		
      
    },
	mounted() {
		this.getcountries();
    this.setImageUploadUrl();
    if(this.myteamid){
      this.getMyteam();
    }

  },
   computed: {
      loadEditor: function () {
        return !this.myteamid || (this.myteamid && this.data.description);
        },
  },
    methods: {
	async getCities(statsid){
		return CountryService.getCities(1,statsid).then(response => {
		this.cities=response.data.data;
		});
	},
	async getStates(countryid){
	return CountryService.getStates(1,countryid).then(response => {
		this.states=response.data.data;
	});
	},
	async getcountries(){
	return CountryService.getcountries().then(response => {
		this.countries=response.data.data;
	});
	},
       updateTextAreaValue(value) {
        this.data.description = value;
      },
		  extractNameById(needle, hayStack) {
	let val = null;
	val = hayStack.filter(function(element){
		if(element['value'] == Number(needle)) {
		return element['label'];
		}
	});
	if(val.length) {
		return val[0]['label'];
	}
	return null;
	},
      submitForm(formName) {
			this.data.country = this.data.country_name;
			this.data.state = this.data.state_name;
			this.data.city = this.data.city_name; 
        this.$refs[formName].validate((valid) => {
          if (valid && !this.myteamid) {
             return MyteamService.add(this.data).then(response => {
                this.showAlertAdded=true;
				this.myteam();
              });
          } else if (valid && this.myteamid) {
            return MyteamService.update(this.myteamid, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	myteam(){
		this.$router.push("/myteam");
	},
	getMyteam(){
    return MyteamService.get(this.myteamid).then(response => {
        this.data = response.data.data;
		if(this.data.country){
          this.getStates(this.data.country);
        }
        if(this.data.state){
          this.getCities(this.data.state);
        }
        this.fileList.push({name:'', url: response.data.data.image});
        return response;
      });
	},
      setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {
          this.data.imageNew = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },
    }
  }
</script>
<style lang="scss" scoped>
.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.upload-demo{ display: flex;gap: 20px;width: 100%;max-width:100%;}
@media screen and (max-width: 767px) {
.upload-demo{ display: unset; gap: unset;width: unset;max-width:unset;}
}
</style>